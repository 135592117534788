<nav class="sticky top-0 w-full bg-gray-800 z-20">
  <!-- *** Start NavBar *** -->

  <div class="border-gray-200 bg-gray-800" [class]="environment">
    <div class="w-full" *transloco="let t; read: 'NavMenu'">
      <div class="flex justify-between items-center px-8 py-5 lg:py-3">
        <div class="flex flex-row justify-end items-end">
          <div class="asm-logo-sm mr-2"></div>
          <div class="flex flex-row">
            <ul class="hidden md:flex text-white text-lg h-full">
              <li class="inline-flex items-center text-white">
                <a routerLink="/" routerLinkActive="bg-gray-600 rounded-md" [routerLinkActiveOptions]="{exact:true}" class="px-3 py-1.5 hover:bg-gray-700 hover:rounded-md">
                  <i class="fa-solid fa-house fa-fw w-8"></i></a>
                </li>

              </ul>
            </div>
          </div>
          @if (authService.isAuthenticated$ | async) {
            <div class="hidden md:flex justify-end">
              <div id="menu-wrapper" class="relative">
                <span (click)="showMenu = !showMenu" id="nav-side-menu-icon" class="fa-solid fa-gear fa-fw cursor-pointer text-xl text-white"></span>
                @if (showMenu) {
                  <div id="nav-side-menu" role="menu" tabindex="0" class="divide-y divide-gray-100">
                    <p class="truncate px-4 py-3.5" role="none">
                      <span class="block text-sm text-gray-500" role="none" >{{ t('SignedInAs') }}</span>
                      <span class="mt-0.5 text-base font-semibold" role="none"> {{ userInfo.Email }}</span>
                    </p>
                    <div class="py-1.5" role="none">
                      <button (click)="goToProfile()" type="button" class="nav-menu-item" role="menuitem" tabindex="-1">{{ t('Profile') }}</button>
                      <button (click)="getReleaseNotes()" type="button" class="nav-menu-item" role="menuitem" tabindex="-1">{{ t('ReleaseNotes') }}</button>
                    </div>
                    <div class="py-1.5" role="none">
                      <button (click)="onLogout()" type="button" class="nav-menu-item"
                      role="menuitem" tabindex="-1">{{ t('Signout') }}</button>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          <button type="button" (click)="showDropdownMenu = !showDropdownMenu"
            class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
            <span class="sr-only">Open main menu</span>
            <i class="fa-regular fa-bars fa-fw fa-xl text-slate-900"></i>
          </button>
          <!-- <a routerLink="/dashboard" class="navbar-brand"><b>ASM</b> Group</a> -->
        </div>
        @if (showDropdownMenu) {
          <div class="flex flex-col md:hidden">
            <div class="fixed inset-0 flex items-start justify-end overflow-y-auto translate-x-">
              <div class="min-h-full w-[min(24rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">
                <h2 class="sr-only">Navigation</h2>
                <button (click)="showDropdownMenu = false" type="button" class="absolute right-6 top-6 flex h-8 w-8 items-center justify-center" tabindex="0">
                  <span class="sr-only">Close navigation</span>
                  <span class="fa-light fa-xmark fa-fw fa-xl"></span>
                </button>
                <nav class="divide-y divide-gray-100 divide-slate-900/10 text-base leading-7 text-slate-900">
                  <div class="px-8 py-5">
                    <a class="flex items-center justify-between overflow-hidden" href="/">
                      <span class="sr-only">ASM Store Portal</span>
                      <div class="asm-logo-sm"></div>
                    </a>
                  </div>
                  <div class="px-8 py-6">
                    <div class="-my-2 items-start space-y-2">
                      <a class="block w-full py-2 font-semibold" href="/">{{ t('Home') }}</a>
                      <!-- <a class="flex w-full items-center py-2 font-semibold" href="/templates">Add Order</a> -->
                    </div>
                  </div>
                  <div class="px-8 py-6">
                    <p class="flex flex-col"><span class="text-sm text-slate-500">{{ t('SignedInAs') }}</span><span class="mt-0.5 truncate">{{ this.userInfo?.Email }}</span></p>
                  </div>
                  <div class="px-8 py-6">
                    <div class="-my-2 space-y-2">
                      <button (click)="goToProfile()" type="button" class="block w-full py-2 text-left">{{ t('Profile') }}</button>
                      <button (click)="getReleaseNotes()" type="button" class="block w-full py-2 text-left">{{ t('ReleaseNotes') }}</button>
                      <button (click)="onLogout()" class="block w-full py-2 text-left" type="button">{{ t('Signout') }}</button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <!-- <div class="absolute inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity opacity-100"></div> -->
  </nav>

  @if (showDropdownMenu) {
    <div class="absolute inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity opacity-100"></div>
  }
  <whats-new-modal [visible]="isReleaseNoteModalVisible" [loading]="isLoading" [notifications]="whatsNewNotifications"></whats-new-modal>
