import { environment } from './environments/environment';

import { enableProdMode } from '@angular/core';
import { AppComponent } from './app/app.cmp';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from 'app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

if (environment.production) {
    enableProdMode();
}