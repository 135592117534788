import { ApplicationConfig, ErrorHandler, Injectable, importProvidersFrom, isDevMode } from "@angular/core";
import { provideRouter } from "@angular/router";
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

import { authHttpInterceptorFn, provideAuth0 } from "@auth0/auth0-angular";

import { provideNgxMask } from "ngx-mask";
import { provideToastr } from "ngx-toastr";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";

import { mainRoutes } from "./app.routes";

import { VersionCheckService } from "./core/services/version-check.service";
import { UserResolverService } from "./core/services/user-resolver.service";
import { SpinnerService } from "./core/spinner/spinner.service";

import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { CookieService } from "ngx-cookie-service";

import { environment } from "environments/environment";
import { API_BASE_URL } from "./core/services/dataservice.svc";
import { Translation, TranslocoLoader, provideTransloco } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { ApplicationInsightsService } from "./core/services/telemetry.service";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule
        ),
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        {
            provide: API_BASE_URL,
            useValue: environment.backendServerUrl
        },
        CookieService,
        VersionCheckService,
        UserResolverService,
        ApplicationInsightsService,
        ConfirmationService,
        SpinnerService,
        
        provideRouter(mainRoutes),
        provideAuth0({ ...environment.auth }),
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        provideAnimations(),
        provideToastr(),
        provideNgxMask(),
        provideCharts(withDefaultRegisterables()),
        provideTransloco({
            config: {
                availableLangs: ['en', 'es', 'fil'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        })
    ]
}