import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AngularPlugin, IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';


// https://github.com/microsoft/applicationinsights-angularplugin-js/blob/main/sample/applicationinsights-angularplugin-sample/src/app/telemetry.service.ts
@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {

    private _router = inject(Router);
    private _authService = inject(AuthService);

    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            connectionString: environment.appInsightsKey,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this._router,
                    errorServices: [new CustomErrorHandler()]
                }
            }
        }
    });

    authenticated: boolean = false;

    constructor() {
        this._authService.isAuthenticated$.subscribe(x => {
            this.authenticated = x;

            if (this.authenticated) {
                this._authService.user$.subscribe(user => {
                    if (user) {
                        this.appInsights.loadAppInsights();
                        if (user?.username) {
                            this.appInsights.setAuthenticatedUserContext(user?.username, undefined, true);
                        }
                    }
                });
            }
        });
    }

    // expose tracking methods
    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
        this.appInsights.trackEvent(event, customProperties);
    }

    startTrackEvent(name?: string) {
        this.appInsights.startTrackEvent(name);
    }

    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }

    trackPageView(pageView?: IPageViewTelemetry) {
        this.appInsights.trackPageView(pageView);
    }

    startTrackPage(name?: string) {
        this.appInsights.startTrackPage(name);
    }

    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        this.appInsights.stopTrackPage(name, url, properties, measurements);
    }

    trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackMetric(metric, properties);
    }

    trackException(exception: IExceptionTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackException(exception, properties);
    }

    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }

    trackDependencyData(dependency: IDependencyTelemetry) {
        this.appInsights.trackDependencyData(dependency);
    }

    flush() {
        this.appInsights.flush();
    }

}

export class CustomErrorHandler implements IErrorService {
    constructor() {
        if (!environment.production) {
            console.log('custom error handler')
        }
    }

    handleError(error: any) {
        if (error && !environment.production) {
            console.error(error);
        }
    }
}
