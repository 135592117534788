import { Routes } from "@angular/router";
import { authGuardFn } from "@auth0/auth0-angular";

export const mainRoutes: Routes = [
    {
        path: '',
        canActivate: [authGuardFn],
        loadComponent: () => import('./home/home/home.component').then(m => m.HomeComponent),
    },
    {
        path: 'logout',
        loadComponent: () => import('./auth/logout/logout.component').then(m => m.LogoutComponent),
    },
    {
        path: 'request-info',
        canActivate: [authGuardFn],
        loadComponent: () => import('./home/request-info/request-info.component').then(c => c.RequestInfoComponent)
    },
    {
        path: 'orders',
        canActivate: [authGuardFn],
        loadComponent: () => import('./orders/orders.cmp').then(c => c.OrdersComponent),
        loadChildren: () => [
            { 
                path: 'new',
                loadComponent: () => import('./orders/order-new/order-new.component').then(c => c.OrderNewComponent)
            }
        ],
    },
    {
        path: 'audits',
        canActivate: [authGuardFn],
        loadChildren: () => [
            { 
                path: 'new', 
                loadComponent: () => import('./audits/audit-new/audit-new.component').then(c => c.AuditNewComponent)
            },
            { 
                path: 'settings', 
                loadComponent: () => import('./audits/audit-settings/audit-settings.component').then(c => c.AuditSettingsComponent)
            }
        ],
    },
    {
        path: 'assets',
        canActivate: [authGuardFn],
        loadChildren: () => [
            {
                path: 'removal',
                loadComponent: () => import('./assets/removal/asset-removal.component').then(c => c.AssetRemovalComponent)
            },
            {
                path: 'service-request',
                loadComponent: () => import('./assets/service-request/service-request.component').then(c => c.ServiceRequestComponent)
            },
            {
                path: 'scheduled',
                loadComponent: () => import('./assets/scheduled-edit/scheduled-edit.component').then(c => c.ScheduledEditComponent)
            },
            {
                path: 'changeapproval',
                loadComponent: () => import('./assets/change-approval/change-approval.component').then(c => c.ChangeApprovalComponent)
            },
            {
                path: 'trailer-setup',
                loadComponent: () => import('./assets/trailer-list/trailer-list.component').then(c => c.TrailerListComponent)
            },
        ],
    },
    {
        path: 'placard',
        canActivate: [authGuardFn],
        loadChildren: () => [
            { 
                path: 'download',
                loadComponent: () => import('./placard/placard-download/placard-download.component').then(c => c.PlacardDownloadComponent) 
            }
        ],
    },
    {
        path: 'acknowledge',
        canActivate: [authGuardFn],
        loadChildren: () => [
            {
                path: 'new-delivery',
                loadComponent: () => import('./acknowledge/acknowledge/acknowledge.component').then(c => c.AcknowledgeComponent)
            },
            {
                path: 'new-pickup',
                loadComponent: () => import('./acknowledge/pickup-acknowledge/pickup-acknowledge.component').then(c => c.PickupAcknowledgeComponent)
            }
        ],
    },

    {
        path: 'profile',
        canActivate: [authGuardFn],
        loadComponent: () => import('./profile/profile.component').then(c => c.ProfileComponent)
    },
    {
        path: 'store-selector',
        canActivate: [authGuardFn],
        loadComponent: () => import('./auth/store-selector/store-selector.component').then(c => c.StoreSelectorComponent)
    },
    {
        path: '**', 
        pathMatch: 'full', 
        loadComponent: () => import('./page-not-found.cmp').then(c => c.PageNotFoundComponent)
    },
]