import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Notification } from '../../core/services/dataservice.svc';

import { AccordionModule } from 'primeng/accordion';
import { SkeletonModule } from 'primeng/skeleton';
import { DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'whats-new-modal',
    templateUrl: './whats-new-modal.component.html',
    styleUrls: ['./whats-new-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DialogModule, SkeletonModule, AccordionModule, DatePipe]
})
export class WhatsNewModalComponent implements OnInit {

    private _notifications: Notification[];

    @Input() loading: boolean;
    @Input() visible: boolean;
    @Input() set notifications(value: Notification[]) {
        this._notifications = value;
        this.isVisible = this.notifications?.length > 0 || this.notifications != undefined;
    }

    get notifications(): Notification[] {
        return this._notifications;
    }

    public isVisible: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }
}
