<p-dialog header="What's New" modal="true" [(visible)]="visible" styleClass="w-full m-4 md:w-[850px]">
  <div class="py-2">
    @if (loading) {
      <div class="flex flex-col space-y-4">
        <p-skeleton width="100%" height="3rem"></p-skeleton>
        <p-skeleton width="100%" height="3rem"></p-skeleton>
        <p-skeleton width="100%" height="3rem"></p-skeleton>
      </div>
    }
    @if (!loading && (!notifications || notifications?.length == 0)) {
      <div class="flex flex-col justify-center items-center p-6 text-gray-800">
        <i class="fa-solid fa-circle-check text-blue-500 text-4xl mb-4"></i>
        <span class=" font-semibold text-xl">No release notes at the moment.</span>
        <p>When there's new a update, they'll show up here.</p>
      </div>
    }
    @if (!loading && notifications && notifications?.length > 0) {
      <p-accordion>
        @for (item of notifications; track item; let i = $index) {
          <p-accordionTab [selected]="i == 0" styleClass="focus:ring-0">
            <ng-template pTemplate="header">
              <div class="flex flex-1 justify-between mr-2">
                <div [innerHTML]="item.title"></div>
                <span class="text-gray-500">{{item.publishedWhen | date: 'MM/dd/yyyy'}}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <div class="p-5 border border-gray-200 notif-dialog-content ql-editor" [innerHTML]="item.content"></div>
            </ng-template>
          </p-accordionTab>
        }
      </p-accordion>
    }
  </div>
</p-dialog>
