export class AppUser {
    username: string;
    isSystemAdmin: boolean;
    isDeveloper: boolean;
    fname: string;
    lname: string;
    createBy: string;
    createDate: Date;
    defaultView: string;
    roles: string[];
    assignedCustomers: Customer[];

    constructor(init?: Partial<AppUser>) {
        Object.assign(this, init);
    }
}

export class Customer {
    id: string;
}

export class UserRole {
    static get Accounting() { return roleConstAccounting; }
    static get AuditCaller_Admin() { return roleConstAuditCaller_Admin; }
    static get Auditor() { return roleConstAuditor; }
    static get CallAuditor() { return roleConstCallAuditor; }
    static get Operation() { return roleConstOperation; }
    static get FullDashboard() { return roleFullDashboard; }
    static get EmergencyDashboard() { return roleEmergencyDashboard; }
    static get AddOrder() { return roleAddOrder; }
    static get AddAudit() { return roleAddAudit; }
}

const roleConstAccounting = "Accounting";
const roleConstAuditCaller_Admin = "AuditCaller_Admin";
const roleConstAuditor = "Auditor";
const roleConstOperation = "Operation";
const roleConstCallAuditor = "CallAuditor";
const roleFullDashboard = "FullDashboard"
const roleEmergencyDashboard = "EmergencyDashboard"
const roleAddOrder = "AddOrder";
const roleAddAudit = "AddAudit";