import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

declare var componentHandler: any;

import { SpinnerState, SpinnerService } from './spinner.service';


@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.cmp.html',
    styles: [`
        .global-busy-indicator {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            background-color: rgba(0, 0, 1, .25);
            z-index: 2000;
        }
        
        .indicator {
            height: 10px;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        
        .progress {
            border-radius: 0;
            height: 10px;
        }
        
        .progress-bar {
            color: black;
        }
    `],
    standalone: true,
    imports: []
})
export class SpinnerComponent implements OnDestroy, OnInit {

    public visible = false;
    private spinnerStateChanged: Subscription;

    constructor(private spinnerService: SpinnerService) { }

    ngOnInit() {
        this.spinnerStateChanged = this.spinnerService.spinnerState
            .subscribe((state: SpinnerState) => {
                this.visible = state.show;
            });
    }

    ngOnDestroy() {
        this.spinnerStateChanged.unsubscribe();
    }

}